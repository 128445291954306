import ChatContainer from './components/chat.jsx';

function App() {
  return (
    <div className="App">
      <ChatContainer/>
    </div>
  );
}

export default App;
