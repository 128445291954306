// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
    width: 600px;
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
  }
  
  .chat-messages-container {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .chat-messages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .message {
    margin: 8px;
    padding: 8px;
    max-width: 80%;
    border-radius: 8px;
  }
  
  .user {
    align-self: flex-end;
    background-color: #e2f0cb;
  }
  
  .bot {
    align-self: flex-start;
    background-color: #bddff2;
  }
  
  .chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f2f2f2;
    border-top: 1px solid #ccc;
    margin-top: auto;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 8px;
    border: none;
    border-radius: inherit;
    margin-right: 10px;
  }
  
  .chat-input button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #45a049;
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/Chat.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,0BAA0B;IAC1B,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".chat-container {\n    width: 600px;\n    margin: 20px auto 0;\n    display: flex;\n    flex-direction: column;\n    border: 1px solid #ccc;\n  }\n  \n  .chat-messages-container {\n    flex-grow: 1;\n    overflow-y: auto;\n  }\n  \n  .chat-messages {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n  \n  .message {\n    margin: 8px;\n    padding: 8px;\n    max-width: 80%;\n    border-radius: 8px;\n  }\n  \n  .user {\n    align-self: flex-end;\n    background-color: #e2f0cb;\n  }\n  \n  .bot {\n    align-self: flex-start;\n    background-color: #bddff2;\n  }\n  \n  .chat-input {\n    display: flex;\n    align-items: center;\n    padding: 10px;\n    background-color: #f2f2f2;\n    border-top: 1px solid #ccc;\n    margin-top: auto;\n  }\n  \n  .chat-input input {\n    flex-grow: 1;\n    padding: 8px;\n    border: none;\n    border-radius: inherit;\n    margin-right: 10px;\n  }\n  \n  .chat-input button {\n    padding: 8px 16px;\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .chat-input button:hover {\n    background-color: #45a049;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
