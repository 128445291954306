import React, { useState } from "react";
import "./Chat.css";

const generateRandomSessionId = () => {
  return Math.random().toString(36).substring(2, 10);
};

function Chat() {
  const [message, setMessage] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  let sessionId = queryParams.get("sessionId");
  if (!sessionId) {
    const randomSessionId = generateRandomSessionId();
    queryParams.set("sessionId", randomSessionId);
    sessionId = randomSessionId;
  }

  const [currentSessionId, setCurrentSessionId] = useState(sessionId);

  const [chatMessages, setChatMessages] = useState(() => {
    const storedMessages =
      JSON.parse(localStorage.getItem(currentSessionId)) || null;

    if (!storedMessages) {
      localStorage.setItem(currentSessionId, JSON.stringify([]));
    }
    return storedMessages || [];
  });

  const ENDPOINT = "/";

  const requestBody = {
    language: "English",
    token: "secret-token",
    debug: false,
    history: chatMessages,
    user: `user-${currentSessionId}`,
    bot: `bot-${currentSessionId}`,
    text: message,
  };

  const sendMessage = () => {
    const sentMessage = {
      senderId: `user-${currentSessionId}`,
      text: message,
    };

    setChatMessages((prevMessages) => [...prevMessages, sentMessage]);

    const queryParams = new URLSearchParams(window.location.search);

    const combinedRequestBody = {
      ...requestBody,
      user_name: queryParams.get("user_name"),
      user_age: Number(queryParams.get("user_age")),
      user_city: queryParams.get("user_city"),
      bot_name: queryParams.get("bot_name"),
      bot_age: Number(queryParams.get("bot_age")),
      bot_city: queryParams.get("bot_city"),
      bot_job: queryParams.get("bot_job"),
    };

    fetch(`${ENDPOINT}?${queryParams.toString()}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(combinedRequestBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to send message");
        }
      })
      .then((responseData) => {
        const responseMessage = {
          senderId: `bot-${currentSessionId}`,
          text: responseData,
        };

        setChatMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, responseMessage];
          localStorage.setItem(
            currentSessionId,
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setMessage("");
  };

  return (
    <div className="chat-container">
      <div className="chat-messages-container">
        <div className="chat-messages">
          {chatMessages.map((msg, index) => (
            <div
              key={index}
              className={`message ${
                msg.senderId.includes("bot") ? "bot" : "user"
              }`}
            >
              <p>{msg.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          placeholder="Type your message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
      <div>Session ID: {currentSessionId}</div>
    </div>
  );
}

export default Chat;
